import { addCollectionsForAddress, addAssetsForCollection, addEventsForAsset } from '../redux/crypto'

import * as Web3 from 'web3'
import { OpenSeaPort, Network } from 'opensea-js'

const HDWalletProvider = require("@truffle/hdwallet-provider");

const OPENSEA_BASE_URL = 'https://api.opensea.io/api/v1'
const OPENSEA_BASE_URL_V2 = 'https://api.opensea.io/api/v2'
const OPENSEA_BASE_URL_RINKEBY = 'https://rinkeby-api.opensea.io/api/v1'

let LOADING_ASSETS = false
let LOADING_ASSETS_POLYGON = false

// export const getCollections = (address, dispatch) => {
//   const apiUrl = `${OPENSEA_BASE_URL}/collections?asset_owner=${address}&offset=0&limit=300`;
//   fetch(apiUrl)
//     .then((res) => res.json())
//     .then((collections) => {
//       dispatch(addCollectionsForAddress(address, collections));
//     });
// };

export const initializeOpensea = () => {
  try {
    const provider = new Web3.providers.HttpProvider('https://mainnet.infura.io')

    return new OpenSeaPort(provider, { networkName: Network.Main, apiKey: process.env.REACT_APP_OPENSEA_API_KEY }, (arg) => console.log(arg));

  } catch (err) {
    console.error(err);
  }
}

export const getListings = async (assetContractAddress, tokenId) => {
  const apiUrl = `${OPENSEA_BASE_URL_V2}/orders/ethereum/seaport/listings?asset_contract_address=${assetContractAddress}&token_ids=${tokenId}&order_by=eth_price&format=json`;
  console.log(apiUrl)
  const res = await fetch(apiUrl, { headers: { 'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY }})
  const data = await res.json()
  return data.orders
}

export const getOffers = async (assetContractAddress, tokenId) => {
  const apiUrl = `${OPENSEA_BASE_URL_V2}/orders/ethereum/seaport/offers?asset_contract_address=${assetContractAddress}&token_ids=${tokenId}&order_by=eth_price&format=json`;
  console.log(apiUrl)
  const res = await fetch(apiUrl, { headers: { 'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY }})
  const data = await res.json()
  return data.orders
}

export const getBestOffer = async (collectionSlug, tokenId) => {
  const apiUrl = `${OPENSEA_BASE_URL_V2}/offers/collection/${collectionSlug}/nfts/${tokenId}/best`
  console.log(apiUrl)
  const res = await fetch(apiUrl, { headers: { 'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY }})
  const data = await res.json()
  return data.price
}

export const getOpenseaMediaUrl = (nft) => {
  // urls include size at end w/ =sSIZE:
  // https://lh3.googleusercontent.com/OBW6ndpdiJuU1ZW_N1WsF60c1Q-6TANtp33VS8HVyCvz-yvh3CUTDhNBG0PLcQXAhxg33fsBXB1DXS-uU7X1H9uxng=s60
  // code below makes sure we use a larger size version of the image than what's returned (which is usually too small)
  let image_url = nft.image_url
  let video_url
  if (image_url && (image_url.indexOf('.svg') === -1 && image_url.indexOf('=s') > -1)) {
      const parts = image_url.split('=')
      if (parts.length > 1) {
        parts[1] = 's300'
      }
      image_url = parts.join('=')
  }
  if (nft.media_url && (nft.media_type === 'video/mp4' || nft.media_url.endsWith('.mp4'))) {
    video_url = nft.media_url
  }
  if (image_url.indexOf('.mp4') > -1) {
    video_url = image_url
  }
  if (!image_url) {
    image_url = 'https://via.placeholder.com/640x640/000?text=Image%20cannot%20be%20displayed'
  }

  // IPFS checks
  if (image_url.includes('/ipfs/')) {
    const parts = image_url.split('/ipfs/')
    image_url = `${process.env.REACT_APP_IPFS_GATEWAY}${parts[parts.length-1]}`
  }
  if (video_url && video_url.includes('/ipfs/')) {
    const parts = video_url.split('/ipfs/')
    video_url = `${process.env.REACT_APP_IPFS_GATEWAY}${parts[parts.length-1]}`
  }

  return [image_url, video_url]
}

export const getAllAssetsPolygon = (address, dispatch) => {
  if (LOADING_ASSETS_POLYGON) {
    return
  }
  LOADING_ASSETS_POLYGON = true
  const apiUrl = `${OPENSEA_BASE_URL_V2}/assets/matic?owner=${address}&limit=50&format=json`;
  fetchByPage(address, dispatch, apiUrl, 0, [], true)
};

export const getAssetsByTokenIds = async (contractAddress, tokenIds = [], onSuccess) => {
  let i = 0
  if (!tokenIds.length) {
    return
  }
  let assets = []
  while (i < tokenIds.length) {
    const currentTokenIds = tokenIds.splice(i, i+30)
    try {
      const apiUrl = `${OPENSEA_BASE_URL}/assets?limit=50&asset_contract_address=${contractAddress}&token_ids=${currentTokenIds.join('&token_ids=')}&format=json`;
      const headers = { 'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY }
      const res = await fetch(apiUrl, { headers })
      const data = await res.json()
      assets = assets.concat(data.assets)

    } catch (err) {
      console.warn('error getting opensea NFTs by contractAddress and tokenIds')
    }
  }
  if (onSuccess) {
    onSuccess(assets)
  }
  return assets
}

export const getAllAssets = (address, dispatch, onSuccess) => {
  if (LOADING_ASSETS) {
    return
  }
  LOADING_ASSETS = true
  const apiUrl = `${OPENSEA_BASE_URL_RINKEBY}/assets?owner=${address}&limit=50&format=json`;
  fetchByPage(address, dispatch, apiUrl, onSuccess)
};

const fetchByPage = (address, dispatch, apiUrl, onSuccess, offset = 0, currentCollection = [], matic=false) => {
  const offsetUrl = apiUrl + `&offset=${offset}`
  fetch(offsetUrl)
    .then((res) => res.json())
    .then((data) => {
      let results = []
      if (!data.detail) {
        if (matic) {
          results = data.results.filter(a => a.collection.safelist_request_status === "verified")
        } else {
          results = data.assets
        }
      } else {
        console.error('opensea api error: ' + data.details)
      }

      currentCollection = currentCollection.concat(results)
      const newOffset = offset + 50
      if (!matic && currentCollection.length < 100 && results.length > 0) {
        fetchByPage(address, dispatch, apiUrl, onSuccess, newOffset, currentCollection, matic)
      } else {
        currentCollection = currentCollection.sort((a,b) => {
          if (a.asset_contract.address > b.asset_contract.address) return 1;
          if (b.asset_contract.address > a.asset_contract.address) return -1;
          if (parseInt(a.token_id) > parseInt(b.token_id)) return 1;
          if (parseInt(b.token_id) > parseInt(a.token_id)) return -1;
          return 0;
        })
        if (currentCollection.length > 0) {
          if (dispatch) {
            dispatch(addCollectionsForAddress(address, currentCollection, true))
          } else {
            LOADING_ASSETS = false
            console.log(currentCollection)
            if (onSuccess) {
              onSuccess(currentCollection)
            }
          }
        }
        if (matic) {
          LOADING_ASSETS_POLYGON = false
        } else {
          LOADING_ASSETS = false
        }
      }
    });
}

// export const getAssetsByCollection = (address, collectionId, dispatch) => {
//   const apiUrl = `${OPENSEA_BASE_URL}/assets?owner=${address}&collection=${collectionId}&order_direction=desc&offset=0&limit=50&format=json`;
//   fetch(apiUrl)
//     .then((res) => res.json())
//     .then((data) => {
//       dispatch(addAssetsForCollection(data.assets));
//     });
// }

const _formatData = (data) => {
  data['contract_address'] = data.contract
  data['chain_token_id'] = data.identifier
  data.token_id = data.identifier
  data['chain'] = data.opensea_url.includes('matic') ? 'polygon' : 'eth'
  return data
}

const getCollection = (slug, onSuccess) => {
  const apiUrl = `${OPENSEA_BASE_URL_V2}/collections/${slug}?format=json`;
  console.log(apiUrl)
  fetch(apiUrl, { headers: { 'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY }})
  .then(res => res.json())
  .then(data => {
    if (onSuccess) {
      onSuccess(data)
    }
  })
}

export const getAsset = (address, tokenId, dispatch, onFailure, rinkeby=false) => {
  const baseUrl = rinkeby ? OPENSEA_BASE_URL_RINKEBY : OPENSEA_BASE_URL_V2
  const apiUrl = `${baseUrl}/chain/ethereum/contract/${address}/nfts/${tokenId}?format=json`;
  let headers = { 'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY }
  if (rinkeby) {
    headers = {}
  }
  fetch(apiUrl, { headers })
    .then((res) => res.json())
    .then((data) => {
      if (data.nft) {
        dispatch(addAssetsForCollection([_formatData(data.nft)]))
      } else {
        dispatch(addAssetsForCollection([], onFailure));
      }
    });
}

export const getAssetEvents = (address, tokenId, dispatch, rinkeby=false) => {
  const baseUrl = rinkeby ? OPENSEA_BASE_URL_RINKEBY : OPENSEA_BASE_URL_V2
  const apiUrl = `${baseUrl}/events?asset_contract_address=${address}&token_id=${tokenId}&event_type=transfer&format=json`;
  let headers = { 'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY }
  if (rinkeby) {
    headers = {}
  }
  fetch(apiUrl, { headers })
    .then((res) => res.json())
    .then((data) => {
      const { asset_events } = data
      if (asset_events) {
        if (asset_events.length === 20) {
          console.warn('WARNING: found 20 asset events. may need to page for more...')
        }
        dispatch(addEventsForAsset(tokenId, data.asset_events));
      }
    });
}

export const getAssetPolygon = (address, tokenId, dispatch) => {
  const apiUrl = `${OPENSEA_BASE_URL_V2}/chain/matic/contract/${address}/nfts/${tokenId}?format=json`;
  let headers = { 'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY }
  fetch(apiUrl, { headers })
    .then((res) => res.json())
    .then((data) => {
      dispatch(addAssetsForCollection([_formatData(data.nft)]));
    });
}

const exportedObject = { getAllAssets, getAllAssetsPolygon, getAsset, getAssetPolygon, getAssetEvents, getOpenseaMediaUrl, getCollection }
export default exportedObject
